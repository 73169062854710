import React from 'react';

const Footer = () => {
  return (
    <div id="footer">
      <p className='color-muted'>Created by Matt Hatoun</p>
    </div>

  );
};

export default Footer;